<script setup>
import { storeToRefs } from 'pinia';
import { useAuthStore } from '~/stores/auth';
// import { requestAzure } from '~/composables/useMsal';
import { useMessageStore } from '~/stores/messages';

// Fetch messages
const messagesStore = useMessageStore();
const { messages: staticKeys } = storeToRefs(messagesStore);

// Load user data
const authStore = useAuthStore();
const { statusObj } = storeToRefs(authStore);

/* /
// Get form configs
const {
  _emailConfig,
  _passwordConfig
} = await useFormFieldConfigs();

/ Load user data
const authStore = useAuthStore();
const { currentUser, statusObj } = storeToRefs(authStore);

// Add dynamic values
const getEmail = computed(() => currentUser.value?.emailAddress || '');
const isPending = computed(() => statusObj.value?.pending);

const emailConfig = ref({
  ..._emailConfig,
  pending: isPending,
  readonly: true,
  val: getEmail
});

const passwordConfig = {
  ..._passwordConfig,
  includeToggle: false,
  readonly: true,
  val: '**********************'
};

const passwordResetButtonConfig = {
  buttonType: 'secondary',
  type: 'div',
  active: true,
  large: true,
  fn: async () => await requestAzure('passwordReset')
}; */
</script>

<template>
    <SideBarWrapper>
        <FormMessage :status-obj="statusObj" />
        <SideBarHeader>
            <SideBarMainHeadline :text="staticKeys?.menu_personaldata_markdown" />
        </SideBarHeader>

        <!--        <h3 class="sidebar-headline">{{ staticKeys?.account_menu_login_headline }}</h3>

            <FormSection>
                <FormText :config="emailConfig" />
                <FormText :config="passwordConfig" />
            </FormSection>

            <UiButton :config="passwordResetButtonConfig">
                {{ staticKeys?.account_form_changepassword }}
            </UiButton>-->

        <h3 class="sidebar-headline">
            {{ staticKeys?.account_menu_personal_headline }}
            <span>
                {{ staticKeys?.account_form_asterisk }}.
            </span>
        </h3>

        <LazyUserAccountPersonalData />
    </SideBarWrapper>
</template>

<style lang="scss">
.sidebar-headline {
    @include sidebar-headline;

    span {
        @include form-asterisk;
    }
}
</style>
